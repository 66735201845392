import React, { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import Cookies from 'js-cookie';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [availableMaps, setAvailableMaps] = useState([]);
    const [gameStatus, setGameStatus] = useState(null);
    const [serverOutput, setServerOutput] = useState([]);

    useEffect(() => {
        const token = Cookies.get('token');
        const newSocket = io(process.env.REACT_APP_URL_BACKEND, {
            path: '/socket.io',
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        setSocket(newSocket);

        newSocket.on('availableMaps', (maps) => {
            setAvailableMaps(maps);
        });

        newSocket.on('gameStatus', (status) => {
            setGameStatus(status);
        });

        newSocket.on('gameOutput', (message) => {
            setServerOutput((prevOutput) => [...prevOutput, message]);
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    const value = {
        socket,
        availableMaps,
        gameStatus,
        serverOutput,
        setServerOutput,
    }; 

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};
