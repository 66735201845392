import React, { useState } from 'react';
import { Box, Button, Input, VStack, useToast } from '@chakra-ui/react';
import { useSocket } from '../context/SocketContext';

const TerminalInput = () => {
  const [command, setCommand] = useState('');
  const toast = useToast();
  const socket = useSocket();

  // Gérer l'envoi de la commande
  const handleSendCommand = () => {
    if (command.trim() === '') {
      toast({
        title: 'Command Error',
        description: 'Command cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Envoyer la commande via le socket
    socket.emit('executeCommand', command);

    // Réinitialiser le champ de commande
    setCommand('');
    toast({
      title: 'Command Sent',
      description: `Command "${command}" has been sent to the server.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <VStack spacing={4} align="stretch" p={4}>
      <Box>
        <Input
          placeholder="Enter command"
          value={command}
          onChange={(e) => setCommand(e.target.value)}
          size="md"
        />
      </Box>
      <Box>
        <Button colorScheme="blue" onClick={handleSendCommand}>
          Send Command
        </Button>
      </Box>
    </VStack>
  );
};

export default TerminalInput;
