import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { useSocket } from '../context/SocketContext';

const ServerOutput = () => {
    const { serverOutput } = useSocket();

    return (
        <Box>
            <Text fontWeight="bold">Server Output:</Text>
            <VStack align="stretch">
                {serverOutput.map((message, index) => (
                    <Text key={index}>{message}</Text>
                ))}
            </VStack>
        </Box>
    );
};

export default ServerOutput;
