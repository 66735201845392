import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './utils/auth/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ServerStatusBanner from './components/ServerStatusBanner';
import Home from './pages/Home';
import Login from './pages/Login';
import Error from './layout/Error';
import Header from './layout/Header';
import { SocketProvider } from './context/SocketContext';

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <SocketProvider>
        {children}
      </SocketProvider>
    </AuthProvider>
  );
}

function App() {
  return (
    <AppProviders>
      <Router>
        <Header />
        <ServerStatusBanner />
        <Routes>
          <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </AppProviders>
  );
}

export default App;
