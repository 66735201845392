import React from 'react';
import { Button } from '@chakra-ui/react';
import { useSocket } from '../context/SocketContext';

const GameControls = () => {
    const { socket, gameStatus } = useSocket();

    const handlePauseGame = () => {
        if (gameStatus?.isGameRunning && !gameStatus?.isGamePaused) {
            socket.emit('pauseGame');
        }
    };

    const handleResumeGame = () => {
        if (gameStatus?.isGameRunning && gameStatus?.isGamePaused) {
            socket.emit('resumeGame');
        }
    };

    const handleStopGame = () => {
        if (gameStatus?.isGameRunning) {
            socket.emit('stopGame');
        }
    };

    return (
        <>
            <Button p={2} mt={2} colorScheme="yellow" onClick={handlePauseGame} isDisabled={!gameStatus?.isGameRunning || gameStatus?.isGamePaused}>
                Pause Game
            </Button>
            <Button p={2} mt={2} colorScheme="green" onClick={handleResumeGame} isDisabled={!gameStatus?.isGameRunning || !gameStatus?.isGamePaused}>
                Resume Game
            </Button>
            <Button p={2} mt={2} colorScheme="red" onClick={handleStopGame} isDisabled={!gameStatus?.isGameRunning}>
                Stop Game
            </Button>
        </>
    );
};

export default GameControls;
