import React from 'react';
import styled from 'styled-components';
import { Flex, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth/AuthContext.jsx";
import { useCustomToast } from "../utils/hooks/toast.js";
import ToggleColorMode from "../components/ToggleColorMode";
import logout1 from "./../assets/logout.svg";

const NavbarContainer = styled(Flex)`
  background-color: #15202B;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const Navbar = () => {
  const { logout, isLoggedIn } = useAuth();
  const { showToast } = useCustomToast();
  const navigate = useNavigate();

  const disconnection = async () => {
    logout();
    navigate('/login');
    showToast('Vous avez été deconnecté !', 2000, true, 'success');
  };

  return (
    <NavbarContainer>
      <Flex align="center">
        <StyledLink to="/">
          <Button colorScheme="blue" variant="solid" size="md">Home</Button>
        </StyledLink>

      </Flex>
      <Flex align="center">
        {isLoggedIn && (
          <Button onClick={disconnection} size="sm" variant="ghost" color="white">
            <img src={logout1} alt="Logout" />
          </Button>
        )}
        <ToggleColorMode />
      </Flex>
    </NavbarContainer>
  );
}

export default Navbar;
