import React from 'react';
import { Box, VStack, StackDivider } from '@chakra-ui/react';
import MapSelector from '../components/MapSelector';
import GameControls from '../components/GameControls';
import ServerOutput from '../components/ServerOutput';
import TerminalInput from '../components/TerminalInput';


const Home = () => {
    return (
            <Box p={4}>
                <VStack spacing={4} align="stretch" divider={<StackDivider borderColor="gray.200" />}>
                    <MapSelector />
                    <GameControls />
                    <ServerOutput />
                    <TerminalInput />
                </VStack>
            </Box>
    );
};

export default Home;
