import React, { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, useDisclosure, VStack } from '@chakra-ui/react';
import { useSocket } from '../context/SocketContext';

const MapSelector = () => {
    const { socket, availableMaps, setServerOutput, gameStatus } = useSocket();
    const [selectedMap, setSelectedMap] = useState('');
    const [modes, setModes] = useState([]);
    const [selectedMode, setSelectedMode] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();

    // Fonction pour ouvrir la modale seulement si aucune partie n'est en cours
    const handleOpenModal = () => {
        if (gameStatus?.isGameRunning) {
            setServerOutput((prevOutput) => [...prevOutput, 'A game is already running. Please stop the current game before launching a new one.']);
        } else {
            onOpen();
        }
    };

    const handleMapSelect = (mapName) => {
        setSelectedMap(mapName);
        const map = availableMaps.find(m => m.name === mapName);
        if (map) {
            setModes(map.modes);
        }
    };

    const handleLaunchMap = () => {
        if (selectedMap && selectedMode) {
            socket.emit('launchMap', { mapName: selectedMap, mode: selectedMode });
            onClose();
        } else {
            setServerOutput((prevOutput) => [...prevOutput, 'Select both a map and mode to launch.']);
        }
    };

    return (
        <Box>
            {/* Bouton pour ouvrir la modale */}
            <Button 
                colorScheme="teal" 
                onClick={handleOpenModal} 
                isDisabled={gameStatus?.isGameRunning}
            >
                {gameStatus?.isGameRunning ? "Game Running" : "Select Map to Launch"}
            </Button>

            {/* Modale pour sélectionner une carte et un mode */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select a Map</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4}>
                            {/* Étape 1: Sélectionner une carte */}
                            <Select
                                placeholder="Choose a map"
                                onChange={(e) => handleMapSelect(e.target.value)}
                                value={selectedMap}
                            >
                                {availableMaps.map((map, index) => (
                                    <option key={index} value={map.name}>
                                        {map.name}
                                    </option>
                                ))}
                            </Select>

                            {/* Étape 2: Sélectionner un mode si une carte est choisie */}
                            {selectedMap && (
                                <Select
                                    placeholder="Select Mode"
                                    onChange={(e) => setSelectedMode(e.target.value)}
                                    value={selectedMode}
                                >
                                    {modes.map((mode, index) => (
                                        <option key={index} value={mode}>
                                            {mode.charAt(0).toUpperCase() + mode.slice(1)}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" onClick={handleLaunchMap} mr={3}>
                            Launch
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default MapSelector;
