import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/auth/AuthContext';

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { isLoggedIn, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!isLoggedIn && location.pathname !== '/login') {
      navigate('/login');
    } else if (adminOnly && userRole !== 'admin') {
      // Redirige vers la page d'accueil (ou toute autre page) si l'utilisateur n'est pas un administrateur
      navigate('/'); // ou toute autre route appropriée
    }
  }, [isLoggedIn, userRole, location, navigate, adminOnly]);

  return isLoggedIn && (!adminOnly || userRole === 'admin') ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
